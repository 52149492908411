import React, { useState, useEffect } from "react";
import events from "../data";
import Deck from "./Deck";
import Hand from "./Hand";
import WrongCard from "./WrongCard";
import Score from "./Score";
import { Link } from "react-router-dom";

import { insertScoreService } from "../services"; // Importa el servicio

import { DragDropContext, Droppable } from "@hello-pangea/dnd";
import "../components/styles/board.css";

function Board({ categories, onResetGame, username, setCurrentScore }) {
  const [hand, setHand] = useState([]);
  const [deck, setDeck] = useState(shuffle(events));
  const [removed, setRemoved] = useState([]);
  const [score, setScore] = useState(0);
  const [pointsPopup, setPointsPopup] = useState({ visible: false, points: 0 });
  const [consecutiveHits, setConsecutiveHits] = useState(0);

  // Function to show points popup
  const showPointsPopup = (points, bonusPoints = 0) => {
    setPointsPopup({ visible: true, points, bonusPoints });
    // Hide the popup after 2 seconds
    setTimeout(() => {
      setPointsPopup({ visible: false, points: 0, bonusPoints: 0 });
    }, 1500);
  };

  useEffect(() => {
    const filteredEvents = events.filter((event) =>
      categories.includes(event.category)
    );
    setDeck(shuffle(filteredEvents));
  }, [categories]);

  useEffect(() => {
    if (hand.length === 12) {
      // Aquí puedes llamar a una función que envíe la puntuación al servidor.
      handleWin();

      // Restaurar aciertos consecutivos a 0
      setConsecutiveHits(0);
    }
  }, [hand, removed]);

  const handleWin = async () => {
    try {
      await insertScoreService(username, score);
      setCurrentScore(score);
      console.log("Score successfully added:", score);
    } catch (error) {
      console.error("Failed to add score:", error);
    }
  };

  useEffect(() => {
    console.log("Consecutive hits updated:", consecutiveHits);
  }, [consecutiveHits]);

  function shuffle(array) {
    let currentIndex = array.length,
      temporaryValue,
      randomIndex;

    while (0 !== currentIndex) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }

    return array;
  }

  const filterDeck = (hand) => {
    return deck.filter(
      (card) =>
        !hand.some((handCard) => handCard.id === card.id) &&
        !removed.some((removedCard) => removedCard.id === card.id)
    );
  };

  const isValidPosition = (card, newHand) => {
    const index = newHand.indexOf(card);
    const cardYear = parseInt(card.date);

    return (
      newHand.slice(0, index).every((c) => parseInt(c.date) <= cardYear) &&
      newHand.slice(index + 1).every((c) => parseInt(c.date) >= cardYear)
    );
  };

  return (
    <DragDropContext
      onDragEnd={(result) => {
        console.log("onDragEnd triggered");
        const { source, destination } = result;

        if (
          !destination ||
          (source.index === destination.index &&
            source.droppableId === destination.droppableId)
        ) {
          return;
        }

        if (destination.droppableId === "hand") {
          const card = deck[source.index];
          const newHand = [...hand];
          newHand.splice(destination.index, 0, card);

          // Calcular diferencia de años por separado
          let yearDifferenceLeft = 0;
          let yearDifferenceRight = 0;

          // Variables para comprobar si hay cartas a la izquierda o derecha
          let isLeftCard = false;
          let isRightCard = false;
          const cardYear = parseInt(card.date);
          if (destination.index > 0) {
            const leftCardYear = parseInt(newHand[destination.index - 1].date);
            yearDifferenceLeft = Math.abs(cardYear - leftCardYear);
            isLeftCard = true; // Hay una carta a la izquierda
          }
          if (destination.index < newHand.length - 1) {
            const rightCardYear = parseInt(newHand[destination.index + 1].date);
            yearDifferenceRight = Math.abs(cardYear - rightCardYear);
            isRightCard = true; // Hay una carta a la derecha
          }

          console.log("Años de diferencia izquierda", yearDifferenceLeft);
          console.log("Años de diferencia derecha", yearDifferenceRight);

          // Calcular multiplicadores según el número de categorías
          let multiplierOnSuccess;
          let multiplierOnFail;
          switch (categories.length) {
            case 1:
              multiplierOnSuccess = 1;
              multiplierOnFail = 5;
              break;
            case 2:
              multiplierOnSuccess = 2;
              multiplierOnFail = 4;
              break;
            case 3:
              multiplierOnSuccess = 3;
              multiplierOnFail = 3;
              break;
            case 4:
              multiplierOnSuccess = 4;
              multiplierOnFail = 2;
              break;
            case 5:
              multiplierOnSuccess = 5;
              multiplierOnFail = 1;
              break;
            default:
              multiplierOnSuccess = 1;
              multiplierOnFail = 1;
              break;
          }

          console.log("Acierto:", multiplierOnSuccess);
          console.log("Error:", multiplierOnFail);

          // Actualizar la puntuación
          console.log("Checking if the card is in a valid position");
          if (isValidPosition(card, newHand)) {
            console.log("Card is in a valid position");
            setHand(newHand);
            setDeck(filterDeck(newHand));

            // Incrementar los aciertos consecutivos.
            setConsecutiveHits((prevHits) => {
              const updatedConsecutiveHits = prevHits + 1;
              console.log("Updated consecutive hits:", updatedConsecutiveHits);

              // Cálculo de puntos de bonificación
              if (updatedConsecutiveHits >= 2) {
                const bonusPoints =
                  100 * Math.pow(2, updatedConsecutiveHits - 1);
                console.log("Bonus por aciertos consecutivos:", bonusPoints);
                setScore((prevScore) => Math.round(prevScore + bonusPoints));
                console.log("Puntos de bonus", bonusPoints);
                showPointsPopup(pointsToAdd, bonusPoints);
              }

              return updatedConsecutiveHits; // Retornar el valor actualizado de consecutiveHits
            });

            // Sumar puntos si es correcto
            let pointsToAdd = 1000;
            if (hand.length > 0) {
              // Si no es la primera carta
              let pointsFromLeft =
                yearDifferenceLeft > 0 ? 1000 / yearDifferenceLeft : 0;
              let pointsFromRight =
                yearDifferenceRight > 0 ? 1000 / yearDifferenceRight : 0;

              // Bonus si la diferencia de años es 0
              if (yearDifferenceLeft === 0 && isLeftCard) {
                pointsFromLeft += 1000; // Añade bonus de 2000 si la diferencia de años es 0 a la izquierda
              }
              if (yearDifferenceRight === 0 && isRightCard) {
                pointsFromRight += 1000; // Añade bonus de 2000 si la diferencia de años es 0 a la derecha
              }

              pointsToAdd = Math.round(
                (pointsFromLeft + pointsFromRight) * multiplierOnSuccess
              );
            }
            setScore((prevScore) => Math.round(prevScore + pointsToAdd));
            console.log("Puntos añadidos:", pointsToAdd);
            showPointsPopup(pointsToAdd);
          } else {
            console.log(
              "Card is in an invalid position, resetting consecutive hits"
            ); // <--- Agrega esto aquí
            setHand(newHand.filter((c) => c.id !== card.id));
            setRemoved([...removed, card]);
            setDeck(filterDeck(newHand));
            // Resetear los aciertos consecutivos a 0
            setConsecutiveHits(0);

            // Restar puntos si es incorrecto
            const minYearDifference = Math.min(
              yearDifferenceLeft,
              yearDifferenceRight
            );
            const pointsToSubtract = minYearDifference * multiplierOnFail;
            setScore((prevScore) => prevScore - pointsToSubtract);
            console.log("Puntos restados:", pointsToSubtract);
            showPointsPopup(-pointsToSubtract);
          }
        }
      }}
    >
      <div className="board">
        <Score score={score} />
        {pointsPopup.visible && (
          <div
            className={`points-popup ${
              pointsPopup.points >= 0 ? "positive" : "negative"
            }`}
          >
            {pointsPopup.points >= 0
              ? `+${pointsPopup.points}`
              : pointsPopup.points}

            {pointsPopup.bonusPoints > 0 && (
              <div className="bonus-points">
                Bonus: +{pointsPopup.bonusPoints}
              </div>
            )}
          </div>
        )}

        {hand.length === 12 ? (
          <div className="message-win">
            <p>¡Has ganado!</p>
            <Link to={"/scores"} className="link-sin-subrayado">
              <h3 className="results-title">Ver resultados</h3>
            </Link>
            <button onClick={onResetGame}>Reiniciar</button>
          </div>
        ) : removed.length === 5 ? (
          <div className="message-lost">
            <p>¡Has perdido!</p>
            <Link to={"/scores"} className="link-sin-subrayado">
              <h3 className="results-title">Ver resultados</h3>
            </Link>
            <button onClick={onResetGame}>Reiniciar</button>
          </div>
        ) : (
          <Droppable droppableId="deck" direction="horizontal">
            {(droppableProvided) => (
              <div
                {...droppableProvided.droppableProps}
                ref={droppableProvided.innerRef}
                className="deck-container"
              >
                <Deck cards={deck} />
              </div>
            )}
          </Droppable>
        )}

        <WrongCard cards={removed} />

        {hand.length < 12 && removed.length < 5 && (
          <div className="fallos">FALLOS RESTANTES: {5 - removed.length}</div>
        )}

        <Droppable droppableId="hand" direction="horizontal">
          {(droppableProvided) => (
            <div
              {...droppableProvided.droppableProps}
              ref={droppableProvided.innerRef}
              className="hand-container"
            >
              <Hand cards={hand} />
              {droppableProvided.placeholder}
            </div>
          )}
        </Droppable>
      </div>
    </DragDropContext>
  );
}

export default Board;
